@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

//header section
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.top-bar {
	padding: 0;
}
.sticky.is-stuck {
	z-index:100;
}

#werk {
	min-height: 500px;
	padding-top:3rem;
	
}
#contact {
	min-height: 500px;
	background-color: white;
	
	 
}
#team {
	min-height: 500px;
	background-color: grey;
	padding-top: 3rem;
}
.header-content {
	height: 70vh;
	min-width: 100%;
	background-image: url(http://localhost:8000/assets/img/27.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	margin-top: 90px;
}
.hoofding-menu {
	height: 90px;
	background: white;
	font-size: 1.5rem;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);
	transition: all 0.5s ease 0.1s;
}
.padding-on-my-header {
	height: 70px;

}
@include breakpoint(small only) {
	.hoofding-menu {
	height: 60px;
	}
	.padding-on-my-header {
	height: 40px;
	}
}

.title-bar, .top-bar ul {
	background-color: white;
}
@include breakpoint(small only) {
.logo-desktop {
	display:none;
}
.innovate-small-menu {
  clear: both;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  text-align: center;
  padding-top: 60px;
  }
ul.innovate-small-menu, .innovate-small-menu>li>a {
	display:block;
}
}

.innovate-header {
	position:relative;
}
.header-titel {
	z-index: 1;
	position:absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	
}
.header-button {
	position:absolute;
	top: 120%;
	left: 50%;
	transform: translateX(-50%);
}
.header-button-link {
	color:#92cd00;
}

.header-slogan h1 {
	text-align:center;
}
@include breakpoint(medium only) {
	.header-slogan h1 {
		font-size: 2rem;
	}
}
@include breakpoint(large only) {
	.header-slogan h1 {
		font-size: 2rem;
	}
}
@include breakpoint(medium down)
{
	.inno {
	font-size: 1rem!important;
	min-width: 9.20rem;
	}
	.large {
	font-size: 0rem;
	}
}
.logo-desktop {
	padding-left: 1rem;
}

//sectie strategie

.topbar-space::before {
	content:"";
  display:block;
  height:70px; /* fixed header height*/
  margin:-70px 0 0; /* negative fixed header height */
}
.anchor{
  display: block;
  height: 40px; /*same height as header*/
  margin-top: -40px; /*same height as header*/
  visibility: hidden;
}

#strategie {
	min-height: 500px;
	background-color: #fff;
	position: relative;
	margin-top: 3rem;
}
.strategie-titel {
	position: relative;
	width: 75rem;
	text-align:center;
}
.lijn {
	border-bottom: 2px solid #92cd00;
	display: inline-block;
	margin: 0 auto;
	padding-bottom: 0.2rem;
	margin-bottom: 2rem;
}
#strategie-blokken {
	height: 1000px;
	width:800px;
	position:relative;
	margin:0 auto;
	text-align:center;
	}
#strategie-blokken h1 {
	margin-bottom: 25px;
}	
	


.box {
	height: 400px;
	width: 400px;
	text-align: center;
	
	
}
.geel {
	background-color: #ffd416;
}
.blauw {
	background-color: #0389fd;
	color:  white;
}
.groen {
	background-color: #1ead38;

}
.grijs {
	background-color: #999;
}
#box-website {
	position: absolute;
	z-index: 10;
	
}

.box-text {
	opacity:0;
}
#box-media.box.blauw {
	position: absolute;
//	top: 75px;
//	left: 400px;
}
#box-opvolging {
	position: absolute;
//	left: 0;
//	top:475px;
}
#box-marketing {
	position: absolute;
//	top: 475px;
//	left: 400px;
}
.vertical-center {
	position:relative;
	top: 50%;
	transform: translate(0, -50%);
}
.cirkel {
	background: #fff;
    background-size: 100%;
    border-radius: 50%;
    padding: 10px;
    width: 200px;
    height: 200px;
    text-align: center;
//    left: 300px;
//    bottom: -300px;
    z-index: 11;
    position: relative;
}
//.pijl {
//	width: 40px;
//	height: 40px;
//	background: red;
//	animation: draai 4s linear infinite;
//}
//@keyframes draai {
//	
//	100% {
//	transform: rotate(360deg);
//	}
//}
.centreren {
	text-align: center;
}
.strategie-website-image {
	background-image: url(http://localhost:8000/assets/img/webdesign.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 400px;
	z-index:1;
}
.strategie-website-content {
	z-index:1;
}

//sectie recent werk

.werk-titel {
	text-align:center;
	padding-bottom:2rem;
}
.masonry-layout {
  column-count: 2;
  column-gap: 0;
}
.masonry-layout__panel {
  break-inside: avoid;
  padding: 5px;
}
.masonry-layout__panel-content {
  padding: 10px;
  border-radius: 10px;
}

//card stijl
.innovate-cardstyle {
	box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.12);
}
.innovate-cardstyle-kk {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.cbp-l-grid-masonry-projects-desc {
	padding-bottom: 10px;
}

//social proof sectie
.social-proof {
	margin:100px 0; 
	min-height: 350px;
	
}
.foto-social-proof {
	width: 200px!important;
	Height: 200px;
	margin:0 auto;
}
.cbp-l-slider-testimonials-wrap {
	background: #dee5e5;
}

// contact section

#contact {
	background-image: url(http://localhost:8000/assets/img/notepad.jpg);
	background-size: cover;
	background-repeat: no repeat;
	background-position: center center;
	color: #fff;

}
.achtergrond {
	background: rgba(0,0,0,0.8);
	padding: 3rem 0;

}
.contacteer-titel, .team-hoofdtitel {
	text-align:center;
}
.contacteer-titel p{
	text-align:left;
}
.contacteer-marketing>label {
	color: #fff;
}
.contacteer-button {
	margin:0 auto;
}
.innovate-team {
	text-align:center;
}
.innovate-maps {
	width: 100%;
}

//overlay map zodat deze niet beweegt tijdens het scrollen
.scrolloff {
    pointer-events: none
}

//footer



.innovate-adres {
	width:100%;
	text-align:center;
	background-color:#4a4a4a;
	color: #fff;
	padding:3rem 0;
}
.innovate-adres>p>span {
	margin-right: 1rem;
	font-size:1rem;
}
.facebook {
	font-size: 3rem;
}
.innovate-copyright {
width: 100%;
text-align:center;
	background-color: #212121;
	color: #fff;
	padding: 0.5rem 0;
}
.innovate-copyright>i {
	margin-right: 1rem;
}

